.fullwidth{
    width:100%;
}

.jumbo{
    font-family: ibmmono;
    color:white;
    padding:1rem;
    background-color: black;
}

.logo{
    letter-spacing: 1rem;
    font-size:500%;
}

.button{
    font-family: roboto;
    letter-spacing: 0.2rem;
    color:white;
    border:0.5px solid white;
    padding:0.5rem;
    background-color: rgba(255,255,255,0.1);
}
.button:hover{
    color:Black;
    text-decoration: none;
    background-color: white;
}

.flexcenter {
  
    color: white;
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    resize: vertical;

  }

.overlaytitle{
    font-family: monospace;
    position:absolute;
    bottom:20%;
    width: 80%; 
    padding:0.3rem;
    color:black;
    font-weight: bold;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rowmargin{
    margin-bottom: 2rem;
    margin-bottom:5rem;
}

img{
    margin-bottom:1rem;
}