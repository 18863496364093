@font-face {
  font-family: kronaone;
  src: url(./assets/fonts/Krona_One/KronaOne-Regular.ttf);
}
@font-face {
  font-family: notosans;
  src: url(./assets/fonts/Noto_Sans/NotoSans-Regular.ttf);
}
@font-face {
  font-family: roboto;
  src: url(./assets/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf);
}
@font-face {
  font-family: fatface;
  src: url(./assets/fonts/Abril_Fatface/AbrilFatface-Regular.ttf);
}
@font-face {
  font-family: bebas;
  src: url(./assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf);
}
@font-face {
  font-family: ibmmono;
  src: url(./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf);
}

body{
  background-color: black;
}

html,body{
  overflow-x: hidden;
}


