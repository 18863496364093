.iframewrapper{
    position: relative;
    overflow: hidden;
    padding-bottom: 64.5%;
}
.orbitviewer{
    position: absolute;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
    width: 100%;
    height: 100%;
    border:2px solid white;
}

@media(max-width:991px){
    .iframewrapper{
        padding:0;
    }
    .orbitviewer{
        width:100%;
        height:70vh;
        position:relative;
    }
}