/* Atom */
.wrapper{
    overflow: hidden;
    width:100%;
    height:100vh;
    z-index:200;
    background-color: black;
}
.text{
    font-size:2rem;
    letter-spacing: 1rem;
    font-family: bebas;
    color:white;
    position: fixed;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding:1rem;
    border: 2px solid white;
    box-shadow: 15px 15px white;
}
.dot {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color:white;
	z-index: 0;
	opacity: 1;
	animation-name: effect-3;
	animation-duration: 5s, 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease, linear;
}

.wrapper::before {

	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -65px 0 0 -65px;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	opacity: 1;
	z-index: 1
}

.wrapper::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -65px 0 0 -65px;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	opacity: 1;
	z-index: 2;
}

.wrapper::after,
.wrapper::before {
	border: 2px solid rgba(189, 195, 199, 0.8);
}

.wrapper::after {
	animation-name: effect-3, effect-1;
	animation-duration: 5s, 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease, linear;
}

.wrapper::before {
	animation-name: effect-3, effect-2;
	animation-duration: 5s, 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease, linear;
}

@keyframes effect-1 {
	0% {
		transform: perspective(1000px) rotate3d(1, 1, 1, 0deg)
	}
	100% {
		transform: perspective(1000px) rotate3d(1, 1, 1, 360deg)
	}
}

@keyframes effect-2 {
	0% {
		transform: perspective(1000px) rotate3d(1, -1, 1, 0deg)
	}
	100% {
		transform: perspective(1000px) rotate3d(1, -1, 1, 360deg)
	}
}

@keyframes effect-3 {
	0%,
	100% {
		opacity: 0
	}
	25%,
	75% {
		opacity: 1
	}
}

.ldsfacebook {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .ldsfacebook div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #fff;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .ldsfacebook div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
  }
  .ldsfacebook div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
  }
  .ldsfacebook div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
  }
  @keyframes lds-facebook {
	0% {
	  top: 8px;
	  height: 64px;
	}
	50%, 100% {
	  top: 24px;
	  height: 32px;
	}
  }