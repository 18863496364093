.white{
    color:white;
}
.mono{
    font-family: ibmmono;
}
.padding1{
    padding:1rem;
}
.icons a{
    color:grey;
}
.icons a:hover{
    color:white;
}